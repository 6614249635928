import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CommonService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get request
   * @param url
   */
  public getForSeller(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Get request
   * @param url
   */
  public get(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Get request
   * @param url
   * @param storeId
   */
  public getWithStoreId(url: string, storeId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'storeId': storeId
      })
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Post request
   * @param url
   * @param body
   */
  public create(url: string, body: any, textResponse?: boolean): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: textResponse ? 'text' as 'json' : 'json'
    };

    return this.http.post(url, body, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Update request
   * @param url
   * @param body
   */
  public update(url: string, body: any, textResponse?: boolean): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: textResponse ? 'text' as 'json' : 'json'
    };
    return this.http.put(url, body, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Patch request
   * @param url
   * @param body
   * @param textResponse
   */
  public patch(url: string, body?: any, textResponse?: boolean): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: textResponse ? 'text' as 'json' : 'json'
    };
    return this.http.patch(url, body, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Delete request
   * @param url
   */
  public delete(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions)
      .pipe(catchError(this.handleError));
  }


  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(error);
  }

}

