/**
 * Get error message from the back response
 * @param response
 * @param defaultMessage
 */
export function extractErrorMessagesFromResponse(response: any, defaultMessage?: string) {
  let messages = '';
  let error;
  try {
    error = JSON.parse(response.error);
  } catch (e) {
    error = response.error;
  }
  const errorMessages = error?.errors;
  if (errorMessages && errorMessages.length > 0) {
    try {
      errorMessages.forEach((item: any) => {
        messages += '- ' + item + '<br/>';
      });
    } catch {
      messages += errorMessages;
    }
  } else if (defaultMessage) {
    messages = defaultMessage;
  }
  return messages;
}

export function getAmountFormat(number: number) {
  if (!number || number == 0) {
    return '0';
  } else {
    // hundreds
    if (number <= 999) {
      return +number.toFixed(2) + '';
    }
    // thousands
    else if (number >= 1000 && number <= 999999) {
      return +(number / 1000).toFixed(2) + 'K';
    }
    // millions
    else if (number >= 1000000 && number <= 999999999) {
      return +(number / 1000000).toFixed(2) + 'M';
    }
    // billions
    else if (number >= 1000000000 && number <= 999999999999) {
      return +(number / 1000000000).toFixed(2) + 'B';
    } else
      return +number.toFixed(2) + '';
  }
}

export const CHART_SCALES_X = {
  x: {
    border: {
      display: true
    },
    grid: {
      display: false,
      drawTicks: true,
    }
  },
  y: {
    border: {
      display: true
    },
    grid: {
      display: true
    },
  }
};
export const CHART_SCALES_Y = {
  y: {
    border: {
      display: true
    },
    grid: {
      display: false,
      drawTicks: true,
    }
  },
  x: {
    border: {
      display: true
    },
    grid: {
      display: true
    },
  }
};
