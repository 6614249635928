import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from '@app/_shared/component/main-layout/main-layout.component';
import {AppAuthGuard} from '@app/_shared/guard/app.authguard';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AppAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'overview',
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path: 'discounts',
        loadChildren: () => import('./discount/discount.module').then(m => m.DiscountModule)
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule)
      },
      {
        path: 'payouts',
        loadChildren: () => import('./payout/payout.module').then(m => m.PayoutModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      }
    ]
  },
  {
    path: 'error',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '**', redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule {
}
