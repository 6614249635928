import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router'
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular'
import {UserService} from "@app/_shared/service/user.service";

@Injectable({ providedIn: 'root' })
export class AppAuthGuard extends KeycloakAuthGuard {

    constructor(protected router: Router,
                private userService: UserService, protected keycloakService: KeycloakService) {
        super(router, keycloakService)
    }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      // Force the user to log in if currently unauthenticated.
      if (!this.authenticated) {
        await this.keycloakService.login({
          redirectUri: window.location.origin + state.url
        });
        return false;
      }
      if(!this.roles.includes('shopyan_affiliate')) {
        this.userService.logout().subscribe();
        // this.socketService.disconnect().then();
        this.keycloakService.logout(window.location.origin + state.url).then();
      }
      // Allow the user to proceed if one of required roles is present.
      return this.roles.includes('shopyan_affiliate');
  }
}
