import { Injectable } from '@angular/core';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
import { LoadingBarService } from '@ngx-loading-bar/core';


export const spinnerConfig: Spinner = {
    fullScreen: false,
    size: 'default',
    bdColor: '#fff',
    color: '#553AD8',
    type: 'ball-clip-rotate'
};

@Injectable({providedIn: 'root'})
export class YanLoaderService {


    constructor(private spinner: NgxSpinnerService,
                private loadingBar: LoadingBarService) {
    }

    startProgressBar(): void {
        this.loadingBar.useRef().start();
    }

    stopProgressBar(): void {
        this.loadingBar.useRef().stop();
    }

    startSpinner(spinnerName: string, bgd?: boolean): void {
        const localConf = {...spinnerConfig};
        if (bgd) {
            localConf.bdColor = '#f1f5f9';
        }
        this.spinner.show(spinnerName, localConf).then();
    }

    stopSpinner(spinnerName: string): void {
        this.spinner.hide(spinnerName).then();
    }

    startLoader(spinnerName: string, bgd?: boolean): void {
        this.startProgressBar();
        this.startSpinner(spinnerName, bgd);
    }

    stopLoader(spinnerName: string): void {
        this.stopSpinner(spinnerName);
        this.stopProgressBar();
    }

}
