export class UserModel {
    id: string;
    username: string;
    lastName: string;
    firstName: string;
    email: string;
    roles: string;
    enabled: boolean;
}

export class AffiliateModel {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  address: string;
  line2: string;
  zipCode: string;
  city: string;
  country: string;
  iban: string;
}

