import {Component, OnDestroy, OnInit} from '@angular/core';
import {faAngleDown, faExternalLink, faPlus, faUsers} from '@fortawesome/free-solid-svg-icons';
import screenfull from 'screenfull';
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {UserService} from "@app/_shared/service/user.service";
import {SocketService} from "@app/_shared/service/socket.service";

@Component({
  selector: 'app-shopyan-header',
  templateUrl: './shopyan-header.component.html',
  styleUrls: ['./shopyan-header.component.scss']
})
export class ShopyanHeaderComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  /**  Icons **/
  faAngleDown = faAngleDown;
  faPlus = faPlus;
  faExternalLink = faExternalLink;

  sfull = screenfull;
  userName: string | undefined;
  avatar = 'ab';

  /** Notifications **/
  receivedData: string[] = [];

  /** Init counters */
  notifCount = 0;
  messageCount = 0;

  public locales: any = [
    {name: "French", code: "fr", country: "fr"},
    {name: "English", code: "en", country: "gb"},
    {name: "العربية", code: "ar", country: "ma"},
    //{name: "Deutsch", code: "de", country: "de"},
    //{name: "Español", code: "es", country: "es"},
  ]

  constructor(private keycloakService: KeycloakService,
              private translate: TranslateService,
              private router: Router,
              private userService: UserService,
              public socketService: SocketService) {
  }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLogged => {
      if (isLogged) {
        this.keycloakService.loadUserProfile().then(profile => {
          this.userName = profile.username;
          this.avatar = this.userName ? this.userName.substring(0, 2) : 'ab';
        })
      } else {
        this.keycloakService.login({
          redirectUri: window.location.origin + this.router.url
        });
      }
    });
  }


  /**
   * Toggle screen
   */
  fullScreenToggle(): void {
    if (screenfull.isEnabled) {
      screenfull.toggle().then();
    }
  }

  /**
   * Get selected local
   */
  get selectedLocale(): any {
    return this.locales.find((locale: any) => locale.code === this.translate.currentLang);
  }

  /**
   * Log out
   */
  logout() {
    this.userService.logout().subscribe();
    // this.socketService.disconnect().then();
    this.keycloakService.logout(window.location.origin + '/overview').then();
  }

  changeLanguage(lang: string) {
    this.userService.updateLang(lang).subscribe(() => {
      this.translate.use(lang);
    });
  }

  ngOnDestroy() {
    // this.socketService.disconnect().then();
    this.subscription.unsubscribe();
  }

  protected readonly faUsers = faUsers;
}
