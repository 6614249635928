import {Component, OnInit} from '@angular/core';
import {faSlidersH, faStar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  faSlidersH = faSlidersH;
  faStar = faStar;

  constructor() {
  }

  ngOnInit(): void {
  }
}
