<div id="shopyan-header">

  <div class="header-lp">
    <a [routerLink]="['/']" class="shopyan-logo"></a>
  </div>
  <div class="header-rp">
    <div class="more" [matMenuTriggerFor]="MoreMenu"></div>
    <a [routerLink]="['/']" class="user-avatar">
      <span class="default-avatar">{{avatar}}</span>
    </a>
    <div class="ics">
      <div class="language" [matMenuTriggerFor]="localeMenu">
        <div class="flag" *ngIf="selectedLocale">
          <span class="fi fi-{{selectedLocale.country | lowercase}}"></span>
        </div>
      </div>
      <div class="fullscreen" [class.full]="sfull.isFullscreen" (click)="fullScreenToggle()">
      </div>
      <a [routerLink]="['/']" class="msgs">
        <div class="count">{{messageCount}}</div>
      </a>
      <div class="notifs" [matMenuTriggerFor]="NotifMenu">
        <div class="count">{{notifCount}}</div>
      </div>
    </div>
  </div>

</div>


<!-- MORE MENU -->
<mat-menu #MoreMenu>
  <div id="more-menu" class="yan-menu">
    <div class="yan-menu-padding">
      <a mat-menu-item [routerLink]="['/overview']">{{ 'COMMON.SHOPYAN_HEADER.OVERVIEW' | translate }}</a>
      <a mat-menu-item [routerLink]="['/discounts']">{{ 'COMMON.SHOPYAN_HEADER.DISCOUNTS' | translate }}</a>
      <a mat-menu-item [routerLink]="['/subscriptions']">{{ 'COMMON.SHOPYAN_HEADER.SUBSCRIPTIONS' | translate }}</a>
      <a mat-menu-item [routerLink]="['/payouts']">{{ 'COMMON.SHOPYAN_HEADER.PAYOUTS' | translate }}</a>
      <a mat-menu-item [routerLink]="['/account']">{{ 'COMMON.SHOPYAN_HEADER.MY_ACCOUNT' | translate }}</a>
      <a mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.HELP_CENTER' | translate }}</a>
      <a mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.COMMUNITY' | translate }}</a>
      <div class="sep"></div>
      <a (click)="logout()" mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.LOG_OUT' | translate }}</a>
    </div>
  </div>
</mat-menu>

<!-- LOCALE MENU -->
<mat-menu #localeMenu>
  <div id="locale-menu" class="yan-menu">
    <div class="locale-item" mat-menu-item (click)="changeLanguage(locale.code)" *ngFor="let locale of locales">
      <div class="flag"><span class="fi fi-{{locale.country | lowercase}}"></span>
      </div>
      <span>{{locale.name}}</span>
    </div>
  </div>
</mat-menu>


<!-- NOTIFICATIONS MENU -->
<mat-menu #NotifMenu>
  <div class="yan-menu" *ngFor="let data of receivedData">
    <div class="yan-menu-padding">
      {{data}}
    </div>
    <div class="sep"></div>
  </div>
</mat-menu>
