import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from '@app/app-routing.module';
import {SharedModule} from '@app/_shared/shared.module';
import {MainLayoutComponent} from './_shared/component/main-layout/main-layout.component';
import {ShopyanHeaderComponent} from '@app/_shared/component/shopyan-header/shopyan-header.component';
import {environment} from '../environments/environment'
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {ToastrModule} from 'ngx-toastr';
import {StoreHttpInterceptor} from '@app/_shared/interceptor/store-http.interceptor';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {TranslateCompiler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateMessageFormatCompiler} from "ngx-translate-messageformat-compiler";
import {DatePipe} from "@angular/common";
import {TRANSLOCO_LOADER} from "@ngneat/transloco";
import {HttpLoader} from "@app/http.loader";
import {ShopyanLayoutComponent} from "@app/_shared/component/shopyan-layout/shopyan-layout.component";
import {LeftMenuComponent} from "@app/_shared/component/shopyan-layout/left-menu/left-menu.component";

@NgModule({
  imports: [
    FormsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      enableHtml: true,
      timeOut: 3600
    }),
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: HttpLoader,
        deps: [HttpClient]
      },

      // compiler configuration
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
  ],
  declarations: [
    AppComponent,
    ShopyanHeaderComponent,
    ShopyanLayoutComponent,
    LeftMenuComponent,
    MainLayoutComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StoreHttpInterceptor,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    DatePipe,
    {provide: TRANSLOCO_LOADER, useClass: HttpLoader},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function initializer(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloak,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      }
    });
}
