<div id="left-menu">

  <div id="lm-header"></div>

  <div id="lm-content">
    <div class="menu-options">
      <div class="option-group home" routerLinkActive="active">
        <a [routerLink]="'/overview'" class="op-label">
          <div class="ic"></div>
          <span>{{ 'COMMON.SHOPYAN_HEADER.OVERVIEW' | translate }}</span>
          <div class="arrow"></div>
        </a>
      </div>
      <div class="option-group discount" routerLinkActive="active">
        <a [routerLink]="'/discounts'" class="op-label">
          <div class="ic"></div>
          <span>{{ 'COMMON.SHOPYAN_HEADER.DISCOUNTS' | translate }}</span>
          <div class="arrow"></div>
        </a>
      </div>
      <div class="option-group subscriptions" routerLinkActive="active">
        <a [routerLink]="'/subscriptions'" class="op-label">
          <div class="ic"></div>
          <span>{{ 'COMMON.SHOPYAN_HEADER.SUBSCRIPTIONS' | translate }}</span>
          <div class="arrow"></div>
        </a>
      </div>
      <div class="option-group payouts" routerLinkActive="active">
        <a [routerLink]="'/payouts'" class="op-label">
          <div class="ic"></div>
          <span>{{ 'COMMON.SHOPYAN_HEADER.PAYOUTS' | translate }}</span>
          <div class="arrow"></div>
        </a>
      </div>
    </div>
  </div>

  <div id="lm-footer">

    <a [routerLink]="['/account']" class="setting yan-btn white-btn">
      <fa-icon [icon]="faSlidersH"></fa-icon>
      <span>{{ 'COMMON.SHOPYAN_HEADER.MY_ACCOUNT' | translate }}</span>
    </a>
  </div>

</div>
