import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {DashboardFilter} from "@app/_shared";

@Injectable({providedIn: 'root'})
export class StatsService {

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }


  getFilterParams(filter?: DashboardFilter): HttpParams {

    let params = new HttpParams();

    if (filter?.periodType) {
      params = params.append('periodType', filter.periodType);
    }
    if (filter?.product) {
      params = params.append('product', filter.product);
    }

    if (filter?.startDate) {
      params = params.append('startDate', this.datePipe.transform(filter.startDate, "dd/MM/yyyy")!);
    }

    if (filter?.endDate) {
      params = params.append('endDate', this.datePipe.transform(filter.endDate, "dd/MM/yyyy")!);
    }

    return params;
  }

  public getStoreOverview(filter:DashboardFilter): Observable<any> {
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(`${environment.storeApiUrl}/affiliate/analytics-stores`, httpOptions);
  }

  getRevenueOverview(filter: DashboardFilter) {
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(`${environment.storeApiUrl}/affiliate/analytics-ca`, httpOptions);
  }

  getCaByDateInterval(filter: DashboardFilter) {
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(`${environment.storeApiUrl}/affiliate/ca-date`, httpOptions);
  }

  getSubscriptionByPlan(filter: DashboardFilter) {
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(`${environment.storeApiUrl}/affiliate/subcsriptions-plan`, httpOptions);
  }

  getSubscriptionByCode(filter: DashboardFilter) {
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(`${environment.storeApiUrl}/affiliate/subcsriptions-code`, httpOptions);
  }
}

